import { FranchisesFormModalTerminal } from '@components/franchises/franchises-form/franchises-form-modal-terminal/franchises-form-modal-terminal.component'
import * as React from 'react'
import { FranchisesFormModalTill } from '@components/franchises/franchises-form/franchises-form-modal-till/franchises-form-modal-till.component'

import { ClientsEditPageBookingsActiveReceiptsModal } from '../pages/clients-edit-page/clients-edit-page-bookings-active-receipts-modal/clients-edit-page-bookings-active-receipts-modal.component'
import { ClientsEditPageBookingsHistoryReceiptsModal } from '../pages/clients-edit-page/clients-edit-page-bookings-history-receipts-modal/clients-edit-page-bookings-history-receipts-modal.component'
import { ClientsEditPagePurchasesReceiptsModal } from '../pages/clients-edit-page/clients-edit-page-purchases-receipts-modal/clients-edit-page-purchases-receipts-modal.component'
import { ClientsEditPageSubscriptionsPauseModal } from '../pages/clients-edit-page/clients-edit-page-subscriptions-pause-modal/clients-edit-page-subscriptions-pause-modal.component'
import { ClientsEditPageSubscriptionsReceiptsModal } from '../pages/clients-edit-page/clients-edit-page-subscriptions-receipts-modal/clients-edit-page-subscriptions-receipts-modal.component'
import { CustomFieldsPageModalCreate } from '../pages/custom-fields-page/custom-fields-page-modal-create/custom-fields-page-modal-create.component'
import { CustomFieldsPageModalEdit } from '../pages/custom-fields-page/custom-fields-page-modal-edit/custom-fields-page-modal-edit.component'
import { ScheduleGroupPageModalBookFromWaiting } from '../pages/schedule-group-page/schedule-group-page-modal-book-from-waiting/schedule-group-page-modal-book-from-waiting.component'
import { ScheduleGroupPageModalBooking } from '../pages/schedule-group-page/schedule-group-page-modal-booking/schedule-group-page-modal-booking.component'
import { ScheduleGroupPageModalComments } from '../pages/schedule-group-page/schedule-group-page-modal-comments/schedule-group-page-modal-comments.component'
import { ScheduleGroupPageModalWaiting } from '../pages/schedule-group-page/schedule-group-page-modal-waiting/schedule-group-page-modal-waiting.component'
import { ScheduleManagementPageModalConfirm } from '../pages/schedule-management-page/schedule-management-page-modal-confirm/schedule-management-page-modal-confirm.component'
import { ScheduleManagementPageModalCreate } from '../pages/schedule-management-page/schedule-management-page-modal-create/schedule-management-page-modal-create.component'
import { ScheduleManagementPageModalEdit } from '../pages/schedule-management-page/schedule-management-page-modal-edit/schedule-management-page-modal-edit.component'
import { ScheduleManagementPageModalError } from '../pages/schedule-management-page/schedule-management-page-modal-error/schedule-management-page-modal-error.component'
import { SchedulePageModalConfirm } from '../pages/schedule-page/schedule-page-modal-confirm/schedule-page-modal-confirm.component'
import { SchedulePageModalCreate } from '../pages/schedule-page/schedule-page-modal-create/schedule-page-modal-create.component'
import { SchedulePageModalError } from '../pages/schedule-page/schedule-page-modal-error/schedule-page-modal-error.component'
import { TransactionsCreatePageModalConfirm } from '../pages/transactions-create-page/transactions-create-page-modal-confirm/transactions-create-page-modal-confirm.component'
import { TransactionsCreatePageModalWaiting } from '../pages/transactions-create-page/transactions-create-page-modal-waiting/transactions-create-page-modal-waiting.component'
import { TransactionsCreatePageModalProducts } from '../pages/transactions-create-page/transactions-create-page-modal-products/transactions-create-page-modal-products.component'
import { TransactionsPageModalRefund } from '../pages/transactions-page/transactions-page-modal-refund/transactions-page-modal-refund.component'
import { TransactionsPageReceiptsModal } from '../pages/transactions-page/transactions-page-receipts-modal/transactions-page-receipts-modal.component'
import { AppModal } from '../types/modal.types'
import { ScheduleGroupPageModalQrReader } from '../pages/schedule-group-page/schedule-group-page-modal-qr-reader/schedule-group-page-modal-qr-reader.component'
import { EmployeesScheduleEditModalConfirm } from '../pages/employees/employees-schedule-edit/ui/modals/employees-schedule-edit-modal-confirm.component'

export function genAppModalComponent(modal: AppModal): React.FC<any> {
  switch (modal) {
    // region ---- schedule page
    case AppModal.SCHEDULE_PAGE_MODAL_CREATE:
      return SchedulePageModalCreate
    case AppModal.SCHEDULE_PAGE_MODAL_CONFIRM:
      return SchedulePageModalConfirm
    case AppModal.SCHEDULE_PAGE_MODAL_ERROR:
      return SchedulePageModalError
    // endregion

    // region ---- schedule management page
    case AppModal.SCHEDULE_MANAGEMENT_PAGE_MODAL_CREATE:
      return ScheduleManagementPageModalCreate
    case AppModal.SCHEDULE_MANAGEMENT_PAGE_MODAL_EDIT:
      return ScheduleManagementPageModalEdit
    case AppModal.SCHEDULE_MANAGEMENT_PAGE_MODAL_CONFIRM:
      return ScheduleManagementPageModalConfirm
    case AppModal.SCHEDULE_MANAGEMENT_PAGE_MODAL_ERROR:
      return ScheduleManagementPageModalError
    // endregion

    // region ---- schedule group page
    case AppModal.SCHEDULE_GROUP_PAGE_MODAL_BOOKING:
      return ScheduleGroupPageModalBooking
    case AppModal.SCHEDULE_GROUP_PAGE_MODAL_WAITING:
      return ScheduleGroupPageModalWaiting
    case AppModal.SCHEDULE_GROUP_PAGE_MODAL_BOOK_FROM_WAITING:
      return ScheduleGroupPageModalBookFromWaiting
    case AppModal.SCHEDULE_GROUP_PAGE_MODAL_COMMENTS:
      return ScheduleGroupPageModalComments
    case AppModal.SCHEDULE_GROUP_PAGE_MODAL_QR_READER:
      return ScheduleGroupPageModalQrReader
    // endregion

    // region ---- employees schedule
    case AppModal.EMPLOYEES_SCHEDULE_EDIT_MODAL_CONFIRM:
      return EmployeesScheduleEditModalConfirm
    // endregion

    // region ---- transactions page
    case AppModal.TRANSACTIONS_PAGE_RECEIPTS_MODAL:
      return TransactionsPageReceiptsModal
    case AppModal.TRANSACTIONS_PAGE_REFUND_MODAL:
      return TransactionsPageModalRefund
    // endregion

    // region ---- transactions create page
    case AppModal.TRANSACTIONS_CREATE_PAGE_MODAL_PRODUCTS:
      return TransactionsCreatePageModalProducts
    case AppModal.TRANSACTIONS_CREATE_PAGE_MODAL_CONFIRM:
      return TransactionsCreatePageModalConfirm
    case AppModal.TRANSACTIONS_CREATE_PAGE_MODAL_WAITING:
      return TransactionsCreatePageModalWaiting
    // endregion

    // region ---- clients edit page page
    case AppModal.CLIENTS_EDIT_PAGE_BOOKINGS_ACTIVE_RECEIPTS_MODAL:
      return ClientsEditPageBookingsActiveReceiptsModal
    case AppModal.CLIENTS_EDIT_PAGE_BOOKINGS_HISTORY_RECEIPTS_MODAL:
      return ClientsEditPageBookingsHistoryReceiptsModal
    case AppModal.CLIENTS_EDIT_PAGE_SUBSCRIPTIONS_PAUSE_MODAL:
      return ClientsEditPageSubscriptionsPauseModal
    case AppModal.CLIENTS_EDIT_PAGE_SUBSCRIPTIONS_RECEIPTS_MODAL:
      return ClientsEditPageSubscriptionsReceiptsModal
    case AppModal.CLIENTS_EDIT_PAGE_PURCHASES_RECEIPTS_MODAL:
      return ClientsEditPagePurchasesReceiptsModal
    // endregion

    // region ---- custom fields page
    case AppModal.CUSTOM_FIELDS_PAGE_MODAL_EDIT:
      return CustomFieldsPageModalEdit
    case AppModal.CUSTOM_FIELDS_PAGE_MODAL_CREATE:
      return CustomFieldsPageModalCreate
    // endregion

    case AppModal.FRANCHISES_PAGE_TERMINAL_FORM:
      return FranchisesFormModalTerminal

    case AppModal.FRANCHISES_PAGE_TILL_FORM:
      return FranchisesFormModalTill
  }
}
