import { FC, useCallback, useEffect, useState } from 'react'
import { Button, Flex, Modal, Typography } from 'antd'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { api } from '@api/api'

import { useStudio } from '../../../hooks/use-studios.hook'
import { genTransactionsPagePath } from '../../../format/path.format'
import { modalActions } from '../../../store/common/modal/modal.slice'
import { genTransactionsCreatePageStatusesData } from '../../../store/pages/transactions-create-page/transactions-create-page-statuses/transactions-create-page-statuses.selectors'
import {
  TransactionStatusAction,
  TransactionStatusItem,
  TransactionStatusStatus,
} from '../../../store/pages/transactions-create-page/transactions-create-page-statuses/transactions-create-page-statuses.types'
import { PageLoader } from '../../../components/page/page-loader/page-loader.component'
import { TransactionStatus } from '../../../types/transactions.types'
import { Nullable } from '../../../types/lang.types'
import { TransactionsApi } from '../../../api/types/transactions-api.types'
import { getCurrentStudio } from '../../../store/common/layout/layout.selectors'
import { getStudios } from '../../../store/common/entity/studio/studio.selectors'
import { PaymentMethod } from '../../../types/payment.types'
import imageSrc from './image.png'
import successSrc from './success.svg'
import errorSrc from './error.svg'
import { TransactionsWaitingModalProps } from './transactions-waiting-modal.types'

import './transactions-waiting-modal.styles.less'

const getImageByStatus = (status: TransactionStatusItem, data: Nullable<TransactionsApi.Transaction>) => {
  if (status) {
    switch (status.action) {
      case TransactionStatusAction.FINISHED: {
        return successSrc
      }
      case TransactionStatusAction.ERROR: {
        return errorSrc
      }

      case TransactionStatusAction.STARTED: {
        return imageSrc
      }
    }
  } else if (data) {
    switch (data.status) {
      case TransactionStatus.WAITING: {
        return imageSrc
      }
      case TransactionStatus.PAID: {
        return successSrc
      }
    }
  }

  return imageSrc
}

const getTitleByStatus = (
  status: TransactionStatusItem,
  data: Nullable<TransactionsApi.Transaction>,
  isReceiptsEnabled: boolean
) => {
  if (data?.paymentMethod === PaymentMethod.CASH) {
    if (status) {
      if (status.status === TransactionStatusStatus.EXPIRING) {
        return 'Отмена покупки'
      }

      if (status.status === TransactionStatusStatus.REFUNDING) {
        return 'Внимание на терминал'
      }
    }
    if (!isReceiptsEnabled) {
      if (status) {
        if (status.status === TransactionStatusStatus.EXPIRING) {
          return 'Отмена покупки'
        }

        if (status.status === TransactionStatusStatus.REFUNDING) {
          return 'Внимание на терминал'
        }

        switch (status.action) {
          case TransactionStatusAction.FINISHED: {
            return 'Покупка оплачена'
          }
          case TransactionStatusAction.ERROR: {
            return 'Покупка не оплачена'
          }

          case TransactionStatusAction.STARTED: {
            return 'Примите наличные'
          }
        }
      }
    } else {
      if (status) {
        if (status.status === TransactionStatusStatus.EXPIRING) {
          return 'Отмена покупки'
        }

        if (status.status === TransactionStatusStatus.REFUNDING) {
          return 'Внимание на терминал'
        }

        if (
          status.status === TransactionStatusStatus.ONLINE_PAYMENT_CONFIRMATION ||
          status.status === TransactionStatusStatus.OFFLINE_PAYMENT_CONFIRMATION
        ) {
          return 'Деньги приняты'
        }

        if (status.status === TransactionStatusStatus.PREPARING_SELL_RECEIPTS) {
          return 'Формируем чек'
        }

        switch (status.action) {
          case TransactionStatusAction.FINISHED: {
            return 'Оплата завершена'
          }
          case TransactionStatusAction.ERROR: {
            return 'Покупка не оплачена'
          }

          case TransactionStatusAction.STARTED: {
            return 'Примите наличные'
          }
        }
      }
    }
  }

  if (data?.paymentMethod === PaymentMethod.CARD) {
    if (status) {
      if (status.status === TransactionStatusStatus.EXPIRING) {
        return 'Отмена покупки'
      }

      if (status.status === TransactionStatusStatus.REFUNDING) {
        return 'Внимание на терминал'
      }
    }
    if (!isReceiptsEnabled) {
      if (status) {
        if (status.status === TransactionStatusStatus.EXPIRING) {
          return 'Отмена покупки'
        }

        if (status.status === TransactionStatusStatus.REFUNDING) {
          return 'Внимание на терминал'
        }

        switch (status.action) {
          case TransactionStatusAction.FINISHED: {
            return 'Покупка оплачена'
          }
          case TransactionStatusAction.ERROR: {
            return 'Покупка не оплачена'
          }

          case TransactionStatusAction.STARTED: {
            return 'Внимание на терминал'
          }
        }
      }
    } else {
      if (status) {
        if (status.status === TransactionStatusStatus.EXPIRING) {
          return 'Отмена покупки'
        }

        if (status.status === TransactionStatusStatus.REFUNDING) {
          return 'Внимание на терминал'
        }

        if (
          status.status === TransactionStatusStatus.ONLINE_PAYMENT_CONFIRMATION ||
          status.status === TransactionStatusStatus.OFFLINE_PAYMENT_CONFIRMATION
        ) {
          return 'Деньги приняты'
        }

        if (status.status === TransactionStatusStatus.PREPARING_SELL_RECEIPTS) {
          return 'Формируем чек'
        }

        switch (status.action) {
          case TransactionStatusAction.FINISHED: {
            return 'Оплата завершена'
          }
          case TransactionStatusAction.ERROR: {
            return 'Покупка не оплачена'
          }

          case TransactionStatusAction.STARTED: {
            return 'Внимание на терминал'
          }
        }
      }
    }
  }

  if (data?.paymentMethod === PaymentMethod.SMS) {
    if (status) {
      if (status.status === TransactionStatusStatus.EXPIRING) {
        return 'Отмена покупки'
      }

      if (status.status === TransactionStatusStatus.REFUNDING) {
        return 'Внимание на терминал'
      }
    }

    if (data) {
      switch (data.status) {
        case TransactionStatus.WAITING: {
          return 'Ссылка на оплату сформирована'
        }
        case TransactionStatus.PAID: {
          return 'Оплата завершена'
        }
      }
    }

    if (!isReceiptsEnabled) {
      if (status) {
        if (status.status === TransactionStatusStatus.EXPIRING) {
          return 'Отмена покупки'
        }

        if (status.status === TransactionStatusStatus.REFUNDING) {
          return 'Внимание на терминал'
        }

        switch (status.action) {
          case TransactionStatusAction.FINISHED: {
            return 'Покупка оплачена'
          }
          case TransactionStatusAction.ERROR: {
            return 'Покупка не оплачена'
          }

          case TransactionStatusAction.STARTED: {
            return 'Фомируем ссылку'
          }
        }
      }
    } else {
      if (status) {
        if (status.status === TransactionStatusStatus.EXPIRING) {
          return 'Отмена покупки'
        }

        if (status.status === TransactionStatusStatus.REFUNDING) {
          return 'Производим возврат'
        }

        if (
          status.status === TransactionStatusStatus.ONLINE_PAYMENT_CONFIRMATION ||
          status.status === TransactionStatusStatus.OFFLINE_PAYMENT_CONFIRMATION
        ) {
          return 'Оплата получена'
        }

        if (status.status === TransactionStatusStatus.PREPARING_SELL_RECEIPTS) {
          return 'Формируем чек'
        }

        switch (status.action) {
          case TransactionStatusAction.FINISHED: {
            return 'Оплата завершена'
          }
          case TransactionStatusAction.ERROR: {
            return 'Покупка не оплачена'
          }

          case TransactionStatusAction.STARTED: {
            return 'Фомируем ссылку'
          }
        }
      }
    }
  }

  // if (status) {
  //   switch (status.action) {
  //     case TransactionStatusAction.FINISHED: {
  //       return 'Транзакция успешно оплачена'
  //     }
  //     case TransactionStatusAction.ERROR: {
  //       return 'Транзакция не оплачена'
  //     }

  //     case TransactionStatusAction.STARTED: {
  //       return 'Обрабатываем транзакцию / Печатаем чек'
  //     }
  //   }
  // }
  else if (data) {
    switch (data.status) {
      case TransactionStatus.WAITING: {
        return 'Обрабатываем транзакцию / Печатаем чек'
      }
      case TransactionStatus.PAID: {
        return 'Транзакция успешно оплачена'
      }
    }
  }

  return 'Обрабатываем транзакцию / Печатаем чек'
}

const getTextByStatus = (
  status: TransactionStatusItem,
  data: Nullable<TransactionsApi.Transaction>,
  isReceiptsEnabled: boolean
) => {
  if (data?.paymentMethod === PaymentMethod.CASH) {
    if (status) {
      if (status.status === TransactionStatusStatus.EXPIRING) {
        return 'Время отведённое на оплату покупки вышло'
      }

      if (status.status === TransactionStatusStatus.REFUNDING) {
        return 'Производим возврат покупки'
      }
    }
    if (!isReceiptsEnabled) {
      if (status) {
        if (status.status === TransactionStatusStatus.EXPIRING) {
          return 'Время отведённое на оплату покупки вышло'
        }

        if (status.status === TransactionStatusStatus.REFUNDING) {
          return 'Производим возврат покупки'
        }

        switch (status.action) {
          case TransactionStatusAction.FINISHED: {
            return 'Покупка успешно оплачена'
          }
          case TransactionStatusAction.ERROR: {
            return 'Покупка не оплачена'
          }

          case TransactionStatusAction.STARTED: {
            return 'Проверьте сумму и после нажмите "Принять оплату".'
          }
        }
      }
    } else {
      if (status) {
        if (status.status === TransactionStatusStatus.EXPIRING) {
          return 'Время отведённое на оплату покупки вышло'
        }

        if (status.status === TransactionStatusStatus.REFUNDING) {
          return 'Производим возврат покупки'
        }

        if (
          status.status === TransactionStatusStatus.ONLINE_PAYMENT_CONFIRMATION ||
          status.status === TransactionStatusStatus.OFFLINE_PAYMENT_CONFIRMATION
        ) {
          return 'Деньги приняты'
        }

        if (status.status === TransactionStatusStatus.PREPARING_SELL_RECEIPTS) {
          return 'Формируем чек'
        }

        switch (status.action) {
          case TransactionStatusAction.FINISHED: {
            return 'Покупка успешно оплачена'
          }
          case TransactionStatusAction.ERROR: {
            return 'Покупка не оплачена'
          }

          case TransactionStatusAction.STARTED: {
            return 'Проверьте сумму и после нажмите "Принять оплату".'
          }
        }
      }
    }
  }

  if (data?.paymentMethod === PaymentMethod.CARD) {
    if (status) {
      if (status.status === TransactionStatusStatus.EXPIRING) {
        return 'Время отведённое на оплату покупки вышло'
      }

      if (status.status === TransactionStatusStatus.REFUNDING) {
        return 'Производим возврат покупки'
      }
    }
    if (!isReceiptsEnabled) {
      if (status) {
        if (status.status === TransactionStatusStatus.EXPIRING) {
          return 'Отмена покупки'
        }

        if (status.status === TransactionStatusStatus.REFUNDING) {
          return 'Внимание на терминал'
        }

        switch (status.action) {
          case TransactionStatusAction.FINISHED: {
            return 'Покупка успешно оплачена'
          }
          case TransactionStatusAction.ERROR: {
            return 'Покупка не оплачена'
          }

          case TransactionStatusAction.STARTED: {
            return 'Примите оплату и нажмите "Принять оплату".'
          }
        }
      }
    } else {
      if (status) {
        if (status.status === TransactionStatusStatus.EXPIRING) {
          return 'Время отведённое на оплату покупки вышло'
        }

        if (status.status === TransactionStatusStatus.REFUNDING) {
          return 'Производим возврат покупки'
        }

        if (
          status.status === TransactionStatusStatus.ONLINE_PAYMENT_CONFIRMATION ||
          status.status === TransactionStatusStatus.OFFLINE_PAYMENT_CONFIRMATION
        ) {
          return 'Готовим информацию для печати чека'
        }

        if (status.status === TransactionStatusStatus.PREPARING_SELL_RECEIPTS) {
          return 'Пару секунд, идёт печать'
        }

        switch (status.action) {
          case TransactionStatusAction.FINISHED: {
            return 'Покупка успешно оплачена'
          }
          case TransactionStatusAction.ERROR: {
            return 'Покупка не оплачена'
          }

          case TransactionStatusAction.STARTED: {
            return 'Примите оплату и нажмите "Принять оплату".'
          }
        }
      }
    }
  }

  if (data?.paymentMethod === PaymentMethod.SMS) {
    if (status) {
      if (status.status === TransactionStatusStatus.EXPIRING) {
        return 'Время отведённое на оплату покупки вышло'
      }

      if (status.status === TransactionStatusStatus.REFUNDING) {
        return 'Формируем команду на возврат покупки'
      }
    }

    if (data) {
      switch (data.status) {
        case TransactionStatus.WAITING: {
          return 'Мы уже отправили СМС клиенту с ссылкой. Её также можно скопировать на странице Транзакции или в карточке клиента.'
        }
        case TransactionStatus.PAID: {
          return 'Покупка успешно оплачена'
        }
      }
    }

    if (!isReceiptsEnabled) {
      if (status) {
        if (status.status === TransactionStatusStatus.EXPIRING) {
          return 'Время отведённое на оплату покупки вышло'
        }

        if (status.status === TransactionStatusStatus.REFUNDING) {
          return 'Формируем команду на возврат покупки'
        }

        switch (status.action) {
          case TransactionStatusAction.FINISHED: {
            return 'Покупка оплачена'
          }
          case TransactionStatusAction.ERROR: {
            return 'Возможно у клиента нет денег на карте или произошёл технический сбой. Сформируйте новую транзакцию.'
          }

          case TransactionStatusAction.STARTED: {
            return 'Ссылка будет отправлена в СМС. Её также можно скопировать на странице Транзакции или в карточке клиента.'
          }
        }
      }
    } else {
      if (status) {
        if (status.status === TransactionStatusStatus.EXPIRING) {
          return 'Время отведённое на оплату покупки вышло'
        }

        if (status.status === TransactionStatusStatus.REFUNDING) {
          return 'Формируем команду на возврат покупки'
        }

        if (
          status.status === TransactionStatusStatus.ONLINE_PAYMENT_CONFIRMATION ||
          status.status === TransactionStatusStatus.OFFLINE_PAYMENT_CONFIRMATION
        ) {
          return 'Готовим информацию для печати чека'
        }

        if (status.status === TransactionStatusStatus.PREPARING_SELL_RECEIPTS) {
          return 'Пару секунд, идёт печать'
        }

        switch (status.action) {
          case TransactionStatusAction.FINISHED: {
            return 'Покупка успешно оплачена'
          }
          case TransactionStatusAction.ERROR: {
            return 'Возможно у клиента нет денег на карте или произошёл технический сбой. Сформируйте новую транзакцию.'
          }

          case TransactionStatusAction.STARTED: {
            return 'Ссылка будет отправлена в СМС. Её также можно скопировать на странице Транзакции или в карточке клиента.'
          }
        }
      }
    }
  }

  // if (status) {
  //   switch (status.action) {
  //     case TransactionStatusAction.FINISHED: {
  //       return 'Окно закроется автоматически через 3 секунды'
  //     }
  //     case TransactionStatusAction.ERROR: {
  //       return 'Возможно у клиента недостаточно средств. Повторите попытку, для этого нажмите ещё раз “Создать транзакцию”'
  //     }

  //     case TransactionStatusAction.STARTED: {
  //       return 'Пожалуйста, примите деньги от клиента и следуйте инструкциям от терминала и кассы'
  //     }
  //   }
  // }
  else if (data) {
    switch (data.status) {
      case TransactionStatus.WAITING: {
        return 'Пожалуйста, примите деньги от клиента и следуйте инструкциям от терминала и кассы'
      }
      case TransactionStatus.PAID: {
        return 'Окно закроется автоматически через 3 секунды'
      }
    }
  }

  return 'Пожалуйста, примите деньги от клиента и следуйте инструкциям от терминала и кассы'
}

export const TransactionsWaitingModal: FC<TransactionsWaitingModalProps> = props => {
  const { transactionId } = props

  const statuses = useSelector(genTransactionsCreatePageStatusesData)
  const [currentTransaction, setCurrentTransaction] = useState<Nullable<TransactionsApi.Transaction>>(null)

  const transactionStatus = statuses[transactionId]

  const dispatch = useDispatch()

  const { studioId } = useStudio()

  const studio = useSelector(getCurrentStudio)

  const studios = useSelector(getStudios)

  console.log('studios = ', studios)

  console.log('studio = ', studio)
  console.log('currentTransaction = ', currentTransaction)

  const { push } = useHistory()

  const getTransaction = async () => {
    if (transactionId) {
      const response = await api.transactionsService.fetchById(transactionId)

      setCurrentTransaction(response.data)
    }
  }

  useEffect(() => {
    getTransaction()
  }, [])

  const onClose = useCallback(() => {
    if (studioId) {
      push(genTransactionsPagePath(studioId))
      dispatch(modalActions.closeLast())
    }
  }, [studioId, modalActions])

  useEffect(() => {
    if (transactionStatus || currentTransaction) {
      const isFinished =
        transactionStatus?.action === TransactionStatusAction.FINISHED ||
        currentTransaction?.status === TransactionStatus.PAID

      if (isFinished) {
        setTimeout(() => {
          onClose()
        }, 3000)
      }
    }
  }, [transactionStatus, currentTransaction, onClose])

  const title = getTitleByStatus(transactionStatus, currentTransaction, !!studio?.organizationEntity?.receiptsEnabled)
  const description = getTextByStatus(
    transactionStatus,
    currentTransaction,
    !!studio?.organizationEntity?.receiptsEnabled
  )
  return (
    <Modal open={true} width={651} title={null} footer={null} onCancel={onClose}>
      {transactionStatus || currentTransaction ? (
        <>
          <Flex gap="middle" vertical justify="center" align="center" className="transactions-waiting-modal">
            <img width={96} src={getImageByStatus(transactionStatus, currentTransaction)} />
            <Typography.Title level={3} className="transactions-waiting-modal__title">
              {title}
            </Typography.Title>
            <Typography.Text className="transactions-waiting-modal__description ">{description}</Typography.Text>
          </Flex>
        </>
      ) : (
        <PageLoader />
      )}
    </Modal>
  )
}
