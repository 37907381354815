import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Client } from '@stomp/stompjs'
import { api } from '@api/api'
import { transactionsCreatePageStatusesActions } from '@store/pages/transactions-create-page/transactions-create-page-statuses/transactions-create-page-statuses.slice'

export const useTransactionsStatuses = () => {
  const dispatch = useDispatch()

  const [currTicket, setCurrTicket] = useState('')

  const getTicket = async () => {
    const { data: ticket } = await api.transactionsService.fetchTicket()

    setCurrTicket(ticket)
  }

  useEffect(() => {
    if (currTicket) {
      const studioId = localStorage.getItem('APP_SELECTED_STUDIO_ID') as string

      const client = new Client({
        webSocketFactory: () => new WebSocket(`wss://api.dev.vivacrm.ru/ws/v1?ticket=${currTicket}`),
        onWebSocketClose: e => {
          console.log('ConnectionCloded = ', e)
        },
        reconnectDelay: 100000,
        onConnect: () => {
          client.subscribe(`/messages/studios/${studioId}/transactions`, message => {
            dispatch(transactionsCreatePageStatusesActions.updateTransactionsStatus(JSON.parse(message.body)))
          })
        },
        debug: message => console.log('message = ', message),
      })

      client.activate()
    }
  }, [currTicket])

  useEffect(() => {
    getTicket()
  }, [])
}
