import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { TransactionStatusItem } from './transactions-create-page-statuses.types'

export interface TransactionsCreatePageStatusesState {
  data: Record<string, TransactionStatusItem>
}

const initialState: TransactionsCreatePageStatusesState = {
  data: {},
}

export const { actions: transactionsCreatePageStatusesActions, reducer: transactionsCreatePageStatusesReducer } =
  createSlice({
    name: '@@transactions-create-page-submit',
    initialState,
    reducers: {
      updateTransactionsStatus: (
        state: Draft<TransactionsCreatePageStatusesState>,
        action: PayloadAction<TransactionStatusItem>
      ) => {
        state.data[action.payload.transactionId] = action.payload
      },

      reset: () => initialState,
    },
  })
